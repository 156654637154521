.rh-feature-box {
    padding: 20px;
    margin-bottom: 20px;
}
.rh-gallery .rh-feature-box {
    padding: 10px;
}
.rh-gallery {
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url(https://images.unsplash.com/uploads/1411599070526e808c923/239f12cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80);
    background-size: cover;
}

.rh-gallery h2 {
    color:#fff;
    text-shadow: black 0 0 4px;
}
.gallery-container .gallery-box {
    display: none;
}

img.ril-image-current.ril__image {
    transform: inherit!important;
    max-width: 1500px;
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-dialog {
    top: 0;
    max-width: none;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

