        .rh-contact-add{
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            font-size: 14px;
            margin: 0 0 20px 0;
        }
        .rh-contact-add p{
            font-size: 20px;
            font-family: 'Playfair Display', serif;
            font-weight: 700;
        }
        .contact-phone a {
            font-size: 35px;
            font-family: Arial;
            line-height: 1.6;
            text-decoration: underline;
        }
        .contact-email a {
            font-size: 25px;
            font-weight: bolder;
            line-height: 2;
        }
        .rh-contact-add .icon-box{
            display: inline-block;
        }
        .rh-contact-add .icon-box i{
            height: 48px;
            width: 48px;
            line-height: 48px;
            text-align: center;
            margin: 0 15px 0 0;
            border-radius: 4px;

            -webkit-transition: all 0.4s ease 0s;
            -moz-transition: all 0.4s ease 0s;
            -o-transition: all 0.4s ease 0s;
            transition: all 0.4s ease 0s;
        }
        .rh-creditcards {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 50px;
        }
        .rh-creditcards span {
            font-size: 30px;
            color: #000;
        }
        .rh-creditcards img {
            width: inherit;
            margin-right: 10px;
        }
        .rh-c-form .form-area .form-group{
            display: inline-block;
        }
        .rh-c-form .form-area h3,
        .rh-c-form .form-area .form-group{
            margin: 0 0 30px 0;
        }
        .form-area .rh .rh-check-btn{
            float: right;
            color: white;
            border: none;
        }
        .reservation-banner{
            margin: 10px auto;
            width: 100%;
            text-align: center;
            color: #d23e12;
            font-weight: 600;
            font-size: 36px;
            line-height: 1.2;
        }
        .reservation-image{
            margin: 10px auto;
            width: 35%;

        }
        .rh-section-title img{
        }
        #map {
            width: 100%;
            height: 637px;
        }
       
        @media screen and (max-width: 1199px){
            .rh-contact-add p{
                font-size: 16px;
            }
            #map {
                height: 565px;
            }
        }
        @media screen and (max-width: 991px){
            .rh-contact-add{
                margin: 0 0 30px 0;
            }
            .rh-contact .col-sm-12:last-child .rh-contact-add{
                margin: 0;
            }
            .form-area .rh{
                margin: 0 0 30px 0;
            }
            #map {
                height: 478px;
            }
        }
        @media screen and (max-width: 480px){
            .rh-c-form .form-area h3{
                font-size: 24px;
            }
            .rh-contact-add p {
                font-size: 16px;
            }
             .contact-phone a {
                font-size: 18px;
            }
             .contact-email a {
                font-size: initial;
            }
            .rh-contact-add .icon-box i {
                height: 40px;
                width: 40px;
                line-height: 40px;
                font-size: 16px;
            }
            .rh-contact-add {
                margin: 0 0 20px 0;
            }
            #map {
                height: 350px;
            }
        }