        .rh-footer {
            width: 100%;
            display: inline-block;
            vertical-align: top;
        }
        .rh-footer-box .footer-logo {
            width: 136px;
            margin: 0 0 30px 0;
        }
        .footer-about {
            font-size: 16px;
            font-family: 'PlayfairDisplay-Regular';
        }
        .footer-about li {
            margin: 10px 0;
        }
        .footer-menu {
            margin: 35px 0 0 0;
        }
        .footer-menu.list li {
            margin: 0 0 10px 0;
        }
        .footer-menu.list li a:hover {
            margin-left: 5px;
        }
        .footer-menu.gallery {
            width: 100%;
            display: inline-block;
            vertical-align: top;
        }
        .footer-menu.gallery li {
            float: left;
            margin: 0 2% 2% 0;
            width: 32%;
            overflow: hidden;
        }
        .footer-menu.gallery li:nth-child(3n+3) {
            margin: 0;
        }
        .footer-menu.gallery li a {
            position: relative;
            overflow: hidden;
            width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
        }
        .footer-menu.gallery li a *,
        .footer-menu.gallery li a *:before,
        .footer-menu.gallery li a *:after {
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;
        }
        .footer-menu.gallery li a img {
            max-width: 100%;
            backface-visibility: hidden;
            vertical-align: top;
        }
        .footer-menu.gallery li:hover a img {
            zoom: 1;
            filter: alpha(opacity=70);
            -webkit-opacity: 0.7;
            -moz-opacity: 0.7;
            -o-opacity: 0.7;
            opacity: 0.7;
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -o-transform: scale(1.2);
            transform: scale(1.2);
        }
        .footer-about li a{
            color: #FFF176!important;
        }
        .footer-btm {
            padding: 30px 0;
            font-family: 'Playfair Display', serif;
            font-weight: 400;
        }
        .footer-btm h6 {
            font-family: 'Playfair Display', serif;
            font-weight: 400;
        }
        @media screen and (max-width: 767px){
            .rh-f-30{
                margin: 0 0 30px 0;
            }
        }
        @media screen and (max-width: 640px){
            .rh-f-100{
                width: 100%;
            }
            .rh-f-50{
                width: 50%;
            }
            .rh-fs-30{
                margin: 0 0 30px 0;
            }
        }
        @media screen and (max-width: 480px){
            .rh-f-50{
                width: 100%;
            }
            .footer-menu {
                margin: 20px 0 0 0;
            }
            .footer-btm {
                padding: 20px 0;
            }
            .footer-btm .pull-right{
                float: left !important;
                margin: 10px 0 0 0;
            }
            .footer-btm .pull-left,
            .footer-btm .pull-right{
                width: 100%;
            }
        }