        .room-amenities p {
            color:#fff;
            font-size:20px;
            line-height: 40px;
        }
        .room-amenities {
               padding: 20px 0;
            }
        .amenities-title{
            text-align: center;
            color: #fff;
            font-size: 20px;
        }
        .amenities-row{
            margin: 0;
            padding: 0 40px;
        }
        .amenities .row{
            width: 100%;
        }
        .rh-detail-bg.list-view-column2{
            padding:91px 0 91px 0;
        }
        .amenities {
            background-color: #1755a5;
            padding: 10px;
        }
        a.rh-check-btn{
            margin-top: 40px
        }
        .book-li
        {
            width: 100%;
            text-align: center;
        }
        .list-view-column2 h2{
            margin: 0 0 5px 0;
        }
        .price {
            text-align: center;
            font-weight: bolder;
            margin-top: 70px;
            font-size: 30px;
        }
        @media screen and (max-width: 991px){
            .rh-detail-bg.list-view-column2 {
                padding: 81px 0 81px 0;
            }
            .populer-author{
                margin: 0 0 30px 15px;
                float: right;
            }
            .rh-reservation.addvertise-grid{
                margin: 0 15px 0px 0px;
            }
        }
        @media screen and (max-width: 767px){
            .rh-detail-bg.list-view-column2 {
                padding: 71px 0 71px 0;
            }
        }
        @media screen and (max-width: 640px){
            .rh-detail-bg.list-view-column2 {
                padding: 50px 0 50px 0;
            }
        }
        /* ========== list View Sorting And Grid View Shorting ========== */
        .sort-view-list ul.pull-right li{
            float: left;
        }
        .sort-view-list .sort-by .buttons button{
            font-size: 18px;
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            display: inline-block;
            padding: 0;
            border: none;
        }
        .sort-by .btn.dropdown-toggle.btn-default{
            height: 40px;
            display: inline-block;
        }
        .sort-view-list .sort-by .buttons button.sort-by-grid{
            margin: 0 10px 0 0;
        }
        .rh-feature-box .feature-detail .rh ul li i{
            font-size: 14px;
            line-height: 24px;
        }
        .sort-by .sort-by-grid .rh-flex{
            width: 100%;
        }
        .sort-by #grid_list { 
            display: inline-block;
            width: 100%;
            margin: 30px 0 0 0;
            vertical-align: top;
        }
        .sort-by .sort-by-list div { 
            width: 100%;
        }
        .sort-by .sort-by-grid div { 
            float: left;
        }
        .sort-by .sort-by-list .rh-feature-box .rh-img a:before,
        .sort-by .sort-by-grid .rh-feature-box .rh-img a:before{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 1;
            background: rgba(213, 166, 119, 0.2);
        }
        .sort-by .sort-by-list .rh-feature-box:hover .rh-img a:before,
        .sort-by .sort-by-grid .rh-feature-box:hover .rh-img a:before{
            opacity: 0;
        }
        .sort-by .sort-by-grid .rh-feature-box .feature-detail{
            padding: 14px 10px 0; 
        }
        .sort-by .sort-by-grid .rh-feature-box .feature-detail .rh p.rh-top{
            display: none;
        }
        .sort-by .sort-by-list .rh-feature-box .feature-detail .rh p.rh-top{
            display: block;
            margin: 0 0 10px 0;
        }
        .sort-by .sort-by-list .rh-feature-box .rh-img{
            float: left;
        }
        .sort-by .sort-by-list .rh-feature-box .feature-detail{
            padding: 0 0 0 20px;
        }
        @media screen and (max-width: 640px){
            .sort-by .sort-by-list .rh-feature-box .feature-detail .rh p.rh-top{
                display: none;
            }
            .rh-feature-box {
                padding: 15px;
            }
            .sort-by .rh-feature-box .feature-detail h4{
                font-size: 18px;
            }
            .rh-feature-box .rating-star{
                margin: 5px 10px 5px 0;
            }
            .sort-by .sort-by-list .rh-feature-box .rh-img{
                width: 100%;
            }
            .sort-by .sort-by-list .rh-feature-box .feature-detail{
                width: 70%;
            }
            .sort-by .sort-by-list .rh-feature-box .feature-detail{
                padding: 0 0 0 15px;
            }
            .sort-by .sort-by-grid .rh-feature-box .feature-detail {
                padding: 14px 0 0 0;
            }
        }
        @media screen and (max-width: 480px){
            .room-amenities p {
                font-size:10px;
                padding-right: 15px;
                line-height: inherit;
            }
            .col-amenities {
                width: auto;
            }
            .room-amenities {
                padding: 0;
            }
            .rh-feature-box {
                padding: 10px;
            }
            .sort-by .sort-by-list .rh-feature-box .feature-detail {
                padding: 0 0 0 10px;
                width: 100%
            }
            .sort-by .rh-feature-box.rh-margin-30 {
                margin: 0 0 15px 0;
            }
            .sort-by .rh-feature-box .feature-detail h4 {
                font-size: 14px;
                font-weight: 700;
            }
            .rh-feature-box .rating-star{
                font-size: 12px;
            }
            .sort-by .sort-by-list .rh-feature-box .feature-detail h4,
            .rh-feature-box .feature-detail .rh p{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: center;
                font-size: large;
                margin-top: 5px;
            }
            .rh-feature-box .rating-star {
                margin: 4px 0;
            }
            .rh-feature-box .feature-detail .rh ul li i{
                font-size: 12px;
                margin-right: 5px;
            }
            .rh-feature-box .feature-detail .rh p{
                font-size: 12px;
            }
            .sort-by .sort-by-grid .rh-feature-box .feature-detail {
                padding: 8px 0 0 0;
            }
            .sort-by .sort-by-grid .rh-feature-box .rating-star,
            .sort-by .sort-by-grid .rh-feature-box .rating-star ul,
            .sort-by .sort-by-grid .rh-feature-box .rating-star span {
                width: 100%;
                display: inline-block;
                line-height: 14px;
            }
            .sort-by .sort-by-grid .rh-feature-box .rating-star ul{
                margin: 0;
            }
            .sort-by .sort-by-list .rh-feature-box .rating-star ul,
            .sort-by .sort-by-list .rh-feature-box .rating-star span{
                line-height: 14px;
            }
            .sort-by .sort-by-grid .rh-feature-box .rating-star span {
                text-align: left;
                display: none;
            }
            .sort-by .sort-by-grid .xs-pr{
                padding-right: 7.5px;
            }
            .sort-by .sort-by-grid .xs-pl{
                padding-left: 7.5px;
            }
            .rating-star span a {
                font-size: 12px;
            }
            .sort-by .sort-by-grid .rh-feature-box .feature-detail .rh ul li:last-child{
                display: none;
            }
            .sort-by .sort-by-grid .rh-feature-box .feature-detail .rh ul li i,
            .sort-by .sort-by-grid .rh-feature-box .feature-detail .rh ul li p{
                line-height: 16px;
            }
        }

        /* ========== Mile Range Slider ========== */
        .rh-mile-range{
            padding: 0 0 30px 0;
            display: inline-block;
            width: 100%;
            vertical-align: top;
        }
        .rh-mile-range .rh-mile-number{
            background: transparent;
            border: none;
            line-height: 14px;
            width: 70px;
            float: right;
            font-family: 'Playfair Display', serif;
            font-weight: 900;
        }
        .rh-mile-range .rh-mile-title{
            float: left;
            line-height: 14px;
        }
        .rh-mile-range .rh-mile-detail{
            padding: 0 0 30px 0;
        }
        @media screen and (max-width: 991px){
            .rh-reservation.rh-filter-mile{
                margin: 0 0 30px 15px;
            }
            .rh-mile-range{
                width: 100%;
                float: right;
            }
            .ui-widget.ui-widget-content{
                margin: 0 11px 0 9px;
            }
        }
        @media screen and (max-width: 767px){
            .rh-mile-range{
                width: 47.5%;
            }
        }
        @media screen and (max-width: 640px){
            .rh-reservation.rh-filter-mile,
            .rh-mile-range{
                margin: 0 0 30px 0px;
            }
            .rh-mile-range{
                width: 100%;
            }
        }

        /* ========== Filter Listings By Miles ========== */
        .rh-filter-list-mile .form-group{
            margin: 0;
        }
        @media screen and (max-width: 991px){
            .rh-mile-range{
                margin: 0 0 30px 15px;
                float: right;
            }
        }