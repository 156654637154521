.rh-brochure {
    padding-top: 40px;
    font-family: arial;
    text-align: center;
}
.vfh-inf-box {
    padding: 40px 60px!important;   
}
.rh-brochure p {
    font-size: 20px;
    margin: 25px 0;
    color: #333333;
}
.rh-brochure span {
    font-size: 16px;
}
.rh-brochure .rh-feature-box :not(h5) {
    text-align: justify;
}
.rh-brochure img {
    width: 70%!important;
    margin-left: 15%;
}
img.logo{
    width: 50px!important;
    margin: 0;
    margin-top: -20px; 
}