        .rh-header ul {
            padding: 8px 0;
        }
        .rh-header ul li {
            margin: 0 15px 0 0;
            display: inline-block;
            vertical-align: top;
        }
        .rh-header ul li:first-child .btn-group .dropdown-toggle{
            padding-left: 0;
        }
        .rh-header ul li a{
            vertical-align: top;
            display: inline-block;
            line-height: 1.5;
        }
        .rh-header .btn-group.bootstrap-select {
            width: 90px !important;
            vertical-align: top;
        }
        .rh-header .btn-group.bootstrap-select .btn {
            padding: 1px 12px;
        }
        .rh-header .bootstrap-select.btn-group .dropdown-menu {
            z-index: 9999;
            margin: 7px 0 0 0;
            border-radius: 0;
            padding: 0;
            box-shadow: none;
            border: none;
            background-clip: unset;
        }
        .rh-header .bootstrap-select.btn-group .dropdown-menu.inner {
            margin: 2px 0;
        }
        .rh-header .btn.dropdown-toggle.btn-default {
            height: auto;
        }
        .rh-header .btn-group.bootstrap-select .dropdown-toggle {
            background: transparent;
            border: none;
        }
        .pool-open {
            text-align: center;
            padding: 20px 0;
            font-size: 36px;
        }
        .warning-message-background {
            background-color: #cce5ff !important;
            border-color: #b8daff !important;
            color: #004085 !important;
        }
        @media screen and (max-width: 480px) {
            .rh-header ul li:nth-child(2){
                margin-right: 0;
            }
            .rh-header ul li:last-child{
                margin-right: 0;
            }
            .rh-header .pull-right .btn-group.bootstrap-select{
                width: 80px !important;
            }
            .bootstrap-select.btn-group .dropdown-toggle .filter-option{
                font-size: 12px;
                line-height: 22px;
            }
            .rh-header ul {
                padding: 2px 0;
            }
            .rh-header ul li a{
                font-size: 12px;
                line-height: 24px;
            }
        }
        /* ========== Nav Menu ========== */
        .navbar-brand {
            padding: 0;
        }
        .navbar-default {
            margin: 0;
            border-radius: 0;
            border: none;
            -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
            -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
            -ms-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
            -o-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
                box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
        }
        .navbar-nav>li>a {
            padding: 9px 20px;
            font-size: 15px;
            border-radius: 5px;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
        }
        .navbar-collapse .nav {
            padding: 34px 0 0 0;
            position: relative;
        }
        .navbar-collapse .nav li {
            margin-right: 5px;
            padding: 0 0 27px 0;
        }
        .navbar-collapse .nav li ul.dropdown-nav {
            min-width: 200px;
            /* Set width of the dropdown */
            display: inline-block;
            position: absolute;
            z-index: 999;
            left: 0;
            top: 35px;
            margin: 60px 0 0 0;
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
        }
        .navbar-collapse .nav li ul.dropdown-nav li {
            margin: 0;
            padding: 0;
            position: relative;
        }
        .navbar-collapse .nav li ul.dropdown-nav li:last-child {
            border: none;
        }
        .navbar-collapse .navbar-nav.navbar-right:last-child{
            margin-right: 0;
        }
        .navbar-collapse .nav li ul.dropdown-nav li a {
            padding: 10px 20px;
            width: 100%;
            display: inline-block;
            position: relative;
        }
        .navbar-collapse .nav li:hover ul.dropdown-nav {
            opacity: 1;
            margin: 30px 0 0 0;
            visibility: visible;
        }
        .navbar-collapse .nav li:hover ul.dropdown-nav .dropdown-nav-list {
            opacity: 0;
            visibility: hidden;
            margin: 30px 0 0 1px;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
        }
        .navbar-collapse .nav li ul.dropdown-nav li:hover .dropdown-nav-list {
            opacity: 1;
            visibility: visible;
            margin: 0px 0 0 1px;
        }
        .dropdown-nav-list {
            position: absolute;
            top: 0;
            width: 100%;
            left: 100%;
        }
        .navbar-brand img {
            width: 100px
        }
        @media screen and (max-width: 1199px){
            .navbar-default .navbar-nav > li > a{
                padding: 9px 15px;
                font-size: 14px;
            }
        }
        @media screen and (max-width: 991px) {
            .navbar-toggle{
                margin-top: 30px; 
            }
            .navbar-toggle:hover,
            .navbar-toggle:focus,
            .navbar-toggle:active{
                background-color: transparent !important;
            }
            .container>.navbar-collapse{
                top: 0;
                z-index: 999;
                margin: 0;
                margin-top: 110px;
            }
            .navbar-collapse{
                position: absolute;
                width: 100%;
                left: 0;
                max-height: 340px;
                border-top: none;
                box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
            }
            .navbar-collapse.in {
                overflow-y: auto;
            }
            .navbar-nav>li>a {
                padding-top: 10px;
                padding-bottom: 10px;
            }
            .navbar-collapse .nav li{
                margin-right: 0px;
                padding: 0;
            }
            .navbar-collapse .nav li a{
                border-radius: 0;
                border-style: none;
                border-width: 0 0 1px 0;
            }
            .navbar-nav>li {
                float: none;
            }
            .navbar-header {
                float: none;
            }
            .navbar-toggle {
                display: block;
            }
            
            .navbar-collapse .nav{
                padding: 15px 0 0 0;
            }
            .navbar-nav {
                float: none!important;
                margin: 0;
            }
            .navbar-collapse .nav li:hover ul.dropdown-nav .dropdown-nav-list {
                opacity: 1;
                visibility: visible;
            }
            .navbar-collapse .nav li ul.dropdown-nav,
            .navbar-collapse .nav li ul.dropdown-nav .dropdown-nav-list{
                display: none;
                visibility: visible;
                opacity: 1;
                position: relative;
            }
            .navbar-collapse .nav li.rh-dropdown ul.dropdown-nav.open,
            .navbar-collapse .nav li.rh-dropdown ul.dropdown-nav.open li.rh-dropdown.active-rh ul.dropdown-nav-list.open{
                display: block;
            }
            .navbar-collapse .nav li ul.dropdown-nav,
            .navbar-collapse .nav li:hover ul.dropdown-nav,
            .navbar-collapse .nav li:hover ul.dropdown-nav .dropdown-nav-list,
            .navbar-collapse .nav li ul.dropdown-nav li:hover .dropdown-nav-list{
                margin: 0;
                position: relative;    
            }
            .navbar-collapse .nav li ul.dropdown-nav .dropdown-nav-list{
                left: 0%;
            }
            .navbar-collapse .nav li ul.dropdown-nav{
                top: 0;
            }
            .navbar-collapse .nav li ul.dropdown-nav,
            .navbar-collapse .nav li ul.dropdown-nav .dropdown-nav-list{
                width: 100%;
            }
            .navbar-collapse .nav li ul.dropdown-nav li a{
                padding: 9px 25px;
            }
            .main-navbar li.rh-dropdown:after,
            .navbar-collapse .nav li ul.dropdown-nav li.rh-dropdown-second:after{
                content: '\f107';
                position: absolute;
                top: 10px;
                right: 10px;
                font-family:'FontAwesome';
                cursor: pointer;
            }
        }
        @media screen and (max-width: 767px) {
            .navbar-collapse .nav li {
                margin-right: 0px;
            }
            .navbar-brand{
                padding: 15px;
            }
            .navbar-brand img{
                max-width: 80px;
            }
            .navbar-toggle{
                margin-top: 20px;
            }
        }