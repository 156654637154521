        .rh-places:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .rh-places {
            background: url(https://res.cloudinary.com/xcytek/image/upload/v1608593633/Hotel%20Villafontana%20Inn/visit_vtpjrb.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: fixed
        }
        .rh-places li a {
            color:#0d74e3;
        }
        .rh-places .rh-section-title h2, .rh-places .rh-section-title p {
            color:#333333!important;
        }
        .rh-tab-section {
            height: 30px;
            display: table;
            clear: both;
            width: 75%;
            margin: 0 auto;
        }
        .rh-tab-section a {
            line-height: 30px;
            text-align: center;
            float: left;
            width: 20%;
            margin: 0!important;
        }
        .rh-tab-section a.active {
            position: relative;
            z-index: 5;
        }
        .rh-tab-section .tab-menu a {
            text-decoration: none;
            display: inline-block;
            width: 100%;
            vertical-align: top;
        }
        .rh-tab-section a i {
            font-size: 30px;
            margin: 20px 0 0 0;
            width: 100%;
        }
        .rh-tab-section a {
            margin: 10px 0 20px 0;
        }
        .rh-tab-section .tab {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            width: 100%;
        }
        .tab-content {
            width: 100%;
            padding: 30px;
            display: block;
            height: 100%;
        }
        .tab-content-img {
            width: 39.6%;
            float: left;
            margin-right: 30px;
        }
        .tab-content-detail {
            margin: 10px 0 0 0;
        }
        .tab-content-detail p {
            margin: 10px 0 20px 0;
        }
        .tab-content-detail,
        .tab-content-btm {
            width: auto;
            display: block;
            vertical-align: top;
            overflow: hidden;
        }
        #tabs-container-tab-Festivals{
            width: max-content;
        }
        @media screen and (max-width: 991px){
            .tab-content-img{
                width: 100%;
                margin: 0 0 30px 0;
            }
            .tab-content-detail, 
            .tab-content-btm{
                width: 100%;
            }
            .tab-content-detail{
                margin: 0;
            }
        }
        @media screen and (max-width: 767px){
            .rh-tab-section{
                width: 100%;
            }
        }
        @media screen and (max-width: 640px){
            .rh-tab-section a{
                width: 33.33%;
            }
            .rh-tab-section a i{
                font-size: 24px;
            }
        }
        @media screen and (max-width: 480px){
            .rh-tab-section a{
                width: 100%;
                border-bottom: 1px solid #4c4c4c;
            }
            #tabs-container-tab-Festivals{
                width: 100%;
            }
            .rh-tab-section a h5{
                display: none;
            }
            .rh-tab-section a i{
                margin: 15px 0;
                font-size: 18px;
            }
            .tab-content{
                padding: 20px;
                height: auto;
            }
            .tab-content-btm .pull-right{
                float: left !important;
                margin: 10px 0 0 0;
            }
        }