        .rh-feature-box {
            text-align: left;
            padding: 20px;
        }
        .rh-img {
            position: relative;
            overflow: hidden;
            width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
        }
        .rh-img *,
        .rh-img *:before,
        .rh-img *:after {
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;
        }
        .rh-img img {
            max-width: 100%;
            backface-visibility: hidden;
            vertical-align: top;
        }
        .rh-img a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }
        .rh-feature-box:hover .rh-img img {
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -o-transform: scale(1.2);
            transform: scale(1.2);
        }
        .rh-img:after{
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.4);
            top: 0;
            left: 0;
            opacity: 0;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;
        }
        .rh-feature-box:hover .rh-img:after{
            opacity: 1;
        }
        .rating-star {
            margin: 10px 10px 10px 0;
            font-size: 16px;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
        }
        .rating-star ul {
            margin: 0 10px 0 0;
        }
        .rating-star ul li {
            float: left;
            margin: 0 2px 0 0;
        }
        .rating-star span a {
            font-size: 14px;
        }
        .rh-feature-box .feature-detail {
            padding: 20px 0 10px;
            display: inline-block;
            width: 100%;
            vertical-align: top;
        }
        .rh-feature-box .feature-detail .rh i {
            float: left;
            font-size: 24px;
            line-height: normal;
            margin-right: 10px;
        }
        .rh-feature-box .feature-detail .rh p {
            display: block;
            overflow: hidden;
        }
        @media screen and (max-width: 991px){
            .rh-feature-box{
                padding: 15px;
            }
            h4 {
                font-size: 18px;
            }
            .rh-feature-box .feature-detail{
                padding: 15px 0 5px;
            }
        }
        @media screen and (max-width: 767px){
            .rh-feature-box{
                padding: 20px;
            }
            .rh-mf-30{
                margin: 0 0 30px 0;
            }
            h4 {
                font-size: 20px;
            }
            .rh-feature-box .feature-detail{
                padding: 20px 0 10px;
            }
        }
        @media screen and (max-width: 320px){
            .rh-feature-box{
                padding: 15px;
            }
            h4 {
                font-size: 18px;
            }
            .rh-feature-box .feature-detail{
                padding: 15px 0 5px;
            }
        }