        .carousel-inner .carousel-item:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+0,ffffff+26,ffffff+30,0e2646+100&0+0,1+99 */
        }
        /*.rh-banner-widgets {
            position: absolute;
            bottom: 60px;
            z-index: 9;
        }*/
        .rh-banner-widgets h1 {
            margin-bottom: 20px;
            text-shadow: 0px 0px 10px black;
        }
        .rh-banner-widgets .rh-price {
            font-size: 25px;
            text-shadow: black 0 0 20px;
        }
        .rh-banner-widgets .book-now {
            font-size: 22px;
            padding: 18px 58px;
            border-style: solid;
            border-width: 2px;
            border-radius: 4px;
            display: inline-block;
            float: right;
        }
        .carousel-indicators li{
            width: 15px!important;
            height: 15px!important;
            border-bottom: none!important;
            border-top: none!important;
            border-radius: 10px!important;
        }

        @media screen and (max-width: 1199px) {
            h1 {
                font-size: 48px;
            }
            /*.rh-banner-widgets .book-now{
                padding: 18px 36px;
                font-size: 20px;
            }*/
        }
        @media screen and (max-width: 991px) {
            .rh-banner-widgets .flex{
                display: inline-block;
                width: 100%;
            }
            h1 {
                font-size: 40px;
            }
            .rh-banner-widgets h1 {
                margin-bottom: 10px;
            }
            .rh-banner-widgets .rh-price {
                font-size: 16px;
            }
            .rh-banner-widgets .book-now{
                float: left;
                margin: 20px 0 0 0;
                padding: 14px 36px;
            }
        }
        @media screen and (max-width: 767px) {
            h1 {
                font-size: 36px;
            }
            .carousel-inner{
                height: 380px;
            }
            .carousel-inner>.carousel-item{
                height: 100%;
            }
            .carousel-inner>.carousel-item>img{
                height: 100%;
            }
            .rh-banner-widgets .book-now{
                font-size: 18px;
            }
        }
        @media screen and (max-width: 640px) {
            .carousel-inner {
                height: 340px;
            }
        }
        @media screen and (max-width: 480px) {
            h1 {
                font-size: 24px;
            }
            .rh-banner-widgets .book-now{
                padding: 9px 20px;
            }
            .carousel-inner {
                height: 155px;
            }
            .carousel-indicators{
                margin-bottom: 0;
            }
            .carousel-indicators li{
                width: 15px;
                height: 15px;
            }
            .carousel-indicators .active{
                width: 15px;
                height: 15px;
            }
            .rh-banner-widgets .book-now {
                font-size: 16px;
            }
        }