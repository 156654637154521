        .rh-newsletter {
            padding: 50px 0;
        }
        .rh-newsletter .rh-news-sign {
            float: left;
            width: 35%
        }
        .rh-newsletter .rh-news-email {
            width: 65%;
            display: block;
            overflow: hidden;
        }
        .rh-newsletter .rh-news-sign h5 {
            margin: 0 0 8px 0;
            line-height: 14px;
        }
        .rh-newsletter .rh-news-sign p {
            line-height: 18px;
        }
        .rh-news-email .news-email-form input {
            width: 82%;
            height: 40px;
            outline: 0;
            padding: 5px 15px;
            font-family: 'Playfair Display', serif;
            font-weight: 900;
            float: left;
            border: none;
        }
        .rh-news-email .news-email-form a {
            font-size: 24px;
            height: 40px;
            width: 18%;
            text-align: center;
            line-height: 40px;
            display: inline-block;
        }
        .rh-newsletter .news-social {
            width: 65%;
            height: 40px;
            line-height: 40px;
            display: block;
            overflow: hidden;
            font-size: 24px;
        }
        .rh-newsletter .news-social li {
            float: left;
            padding: 0 18px;
        }
        .news-social i.fa:hover {
            color: #0d51e5!important;
        }
        @media screen and (max-width: 1199px){
            .rh-n-30{
                margin: 0 0 20px 0;
            }
        }
        @media screen and (max-width: 640px){
            .rh-newsletter .rh-news-sign,
            .rh-newsletter .rh-news-email,
            .rh-newsletter .news-social{
                width: 50%;
            }
            .rh-newsletter .news-social{
                font-size: 18px;
            }
            .rh-newsletter .news-social li{
                padding: 0 14px;
            }
            .rh-newsletter {
                padding: 40px 0;
            }
        }
        @media screen and (max-width: 480px){
            .rh-newsletter .rh-news-sign,
            .rh-newsletter .rh-news-email,
            .rh-newsletter .news-social{
                width: 100%;
            }
            .rh-newsletter .rh-news-sign{
                margin: 0 0 20px 0;
            }
            .rh-newsletter {
                padding: 30px 0;
            }
        }